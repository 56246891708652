import $ from "jquery";
import "block-ui";
import "bootstrap";
import moment from "moment";
import "moment/locale/it.js";
import { handleDefaultError } from "../util";
//import "bootstrap-session-timeout";

$.ajaxSetup({
    headers: { "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content") },
});

$(document).ajaxError(function (event, jqXHR, ajaxSettings, thrownError) {
    if (thrownError === "abort") return;
    if (handleDefaultError(jqXHR)) return;
    if (typeof Sentry !== "undefined") {
        Sentry.captureException(new Error(thrownError || jqXHR.statusText), {
            extra: {
                type: ajaxSettings.type,
                url: ajaxSettings.url,
                data: ajaxSettings.data,
                status: jqXHR.status,
                error: thrownError || jqXHR.statusText,
                response: jqXHR.responseJSON || jqXHR.responseText?.substring(0, 100),
            },
        });
    }
});

if (__DEBUG) {
    window["__jQuery"] = $;
}

moment.locale("it");

// $(() =>
// {
//     // gestione timeout sessione
//     $.sessionTimeout(
//     {
//         title: "Sessione in scadenza",
//         message: "La tua sessione sta per scadere.",
//         keepAliveButton: "Rimani connesso",

//         warnAfter: 5340000,         // 89 min
//         redirAfter: 5400000,        // 90 min
//         keepAliveInterval: 5400000, // 90 min

//         keepAliveUrl: "/keep-alive", // per mantenimento sessione server attiva
//         redirUrl: "" // ricarica la pagina
//     });

//     // rimuove bottone per LOGOUT
//     $("div#session-timeout-dialog button#session-timeout-dialog-logout").remove();
// });
